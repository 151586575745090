import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Api from "gurhan/helpers/Api";
document.body.classList.remove("overflow-hidden");
Vue.config.productionTip = false;

import i18n from "./plugins/i18n.js";

Vue.prototype.$custom$t = (str, obj) => {
  // use `$te` and `$t
  const companyId = store.state.User.settings.id;
  const customStr = str + "Custom." + companyId;
  return i18n.t && i18n.te
    ? i18n.te(customStr)
      ? i18n.t(customStr, obj)
      : i18n.t(str, obj)
    : i18n.t(str, obj)
    ? i18n.t(str, obj)
    : i18n.t(str, obj);
};

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import VueTour from "vue-tour";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

Vue.prototype.$api = new Api(
  {
    errorStatus: [400, 401, 403, 500, 404, null, 502, 503],
    // baseUrl: "https://api.gokceozelacademy.com/",
    baseUrl: "http://192.168.1.96:5003/",
    bearerToken: true,
    loginRouterName: "SignIn",
    loginUrl: "Users/AdminLogin",
    vueRouter: true,
    store,
  },
  router
);
Vue.prototype.$apiForRez = new Api({
  errorStatus: [400, 401, 403, 500, 404, null, 502, 503],
  baseUrl: "https://api.rezzervasyon.com/",
  bearerToken: true,
  loginRouterName: "SignIn",
  loginUrl: "Users/Login",
  store,
});
Vue.prototype.$apiHeocademy = new Api({
  errorStatus: [400, 401, 403, 500, 404, null, 502, 503],
  baseUrl: "https://api.heocademy.com/",
  bearerToken: true,
  loginRouterName: "SignIn",
  loginUrl: "Users/Login",
  store,
});
import Loading from "./components/Shared/Loading.vue";
Vue.component("Loading", Loading);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
