import Vue from "vue";
const vm = Vue.prototype;
import i18n from "../../plugins/i18n";

export default {
  state: {
    newCustomerIsLoading: false, // yeni ürün loadingi
    customerListRequested: false, // daha önce ürün verisi çekilmiş mi?
    customersAreLoading: false, // ürünler listesi loadingi
    customers: [], // ürünler listesi
    newInstructorIsLoading: false, // yeni ürün loadingi
    instructorListRequested: false, // daha önce ürün verisi çekilmiş mi?
    instructorsAreLoading: false, // ürünler listesi loadingi
    instructors: [], // ürünler listesi
    count: 0, // kaç adet ürün bulunuyor bilgisi.
    instructorCount: 0, // kaç adet ürün bulunuyor bilgisi.
  },
  mutations: {
    // ürün isteğini resetle
    RESET_CUSTOMER_LIST(state, payload) {
      state.customerListRequested = payload;
    },
    // ürünlerin loadingi
    SET_CUSTOMERS_ARE_LOADING(state, payload) {
      state.customersAreLoading = payload;
    },
    // ürünlerin loadingi
    SET_CUSTOMERS_INSTRUCTOR_ARE_LOADING(state, payload) {
      state.instructorsAreLoading = payload;
    },
    // ürünleri projeye ekle
    SET_CUSTOMERS(state, payload) {
      if (!state.customerListRequested) state.customers = [];
      if (payload.search !== "") {
        state.customers = [];
        state.customerListRequested = false;
      } else state.customerListRequested = true;

      state.customers = [...state.customers, ...payload.data];
      state.count = payload.count;
    },
    // ürünleri projeye ekle
    SET_CUSTOMERS_INSTRUCTOR(state, payload) {
      if (!state.instructorListRequested) state.instructors = [];
      if (payload.search !== "") {
        state.instructors = [];
        state.instructorListRequested = false;
      } else state.instructorListRequested = true;

      state.instructors = [...state.instructors, ...payload.data];
      state.instructorCount = payload.count;
    },
    // öğrenciyi yeni eklerkenki loading
    SET_NEW_CUSTOMER_INSTRUCTOR_IS_LOADING(state, payload) {
      state.newInstructorIsLoading = payload;
    },
    // eklenen öğrenciyi ekle
    SET_NEW_CUSTOMER_INSTRUCTOR_TO_LIST(state, payload) {
      state.instructors.unshift(payload);
      state.instructorCount++;
    },
    // öğrenciyi yeni eklerkenki loading
    SET_NEW_CUSTOMER_IS_LOADING(state, payload) {
      state.newCustomerIsLoading = payload;
    },
    // eklenen öğrenciyi ekle
    SET_NEW_CUSTOMER_TO_LIST(state, payload) {
      state.customers.unshift(payload);
      state.count++;
    },
    // güncellenen öğrenciyi ekle
    SET_UPDATE_CUSTOMER_TO_LIST(state, payload) {
      const customerIndex = state.customers.findIndex(
        (c) => c.id === payload.id
      );
      if (customerIndex !== -1)
        state.customers[customerIndex] = {
          ...state.customers[customerIndex],
          ...payload,
        };
    },
    // öğrenciyi sil
    SET_REMOVE_CUSTOMER(state, { id }) {
      const customerIndex = state.customers.findIndex((c) => c.id === id);
      if (customerIndex !== -1) state.customers.splice(customerIndex, 1);
    },
  },
  actions: {
    // ürünleri getir
    async GET_CUSTOMERS_INSTRUCTOR(
      { state, commit },
      { page = 1, search = "", limit = 12 }
    ) {
      if (
        state.instructors.length !== 0 &&
        (search === "") & state.instructorListRequested &&
        state.instructors.map((instructor) => instructor.page).includes(page)
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_CUSTOMERS_INSTRUCTOR_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Users?roleId=3&page=${page}&searchKey=${search}&limit=${limit}`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_CUSTOMERS_INSTRUCTOR", {
          count: response.data.count || 0,
          search,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
            };
          }),
        });
      else commit("SET_CUSTOMERS_INSTRUCTOR", []);
      commit("SET_CUSTOMERS_INSTRUCTOR_ARE_LOADING", false);
    },
    // ürünleri getir
    async GET_CUSTOMERS(
      { state, commit },
      { page = 1, search = "", limit = 12 }
    ) {
      if (
        state.customers.length !== 0 &&
        (search === "") & state.customerListRequested &&
        state.customers.map((customer) => customer.page).includes(page)
      )
        return; // daha önce liste çekilmişse return at.
      commit("SET_CUSTOMERS_ARE_LOADING", true);
      const response = await vm.$api.get(
        `Users?page=${page}&searchKey=${search}&limit=${limit}`
      );
      if (response.message === "OK" && response.result === "OK")
        commit("SET_CUSTOMERS", {
          count: response.data.count || 0,
          search,
          data: response.data.items.map((d) => {
            // .items gelecek.
            return {
              ...d,
              page,
            };
          }),
        });
      else commit("SET_CUSTOMERS", []);
      commit("SET_CUSTOMERS_ARE_LOADING", false);
    },
    // yeni öğrenciyi kayıt etme
    async SEND_NEW_CUSTOMER({ commit }, { fd, form }) {
      commit("SET_NEW_CUSTOMER_IS_LOADING", true);
      commit("SET_CUSTOMERS_ARE_LOADING", true);
      const response = await vm.$api.post(`Users/Register`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_CUSTOMER_TO_LIST", {
          ...form,
          id: response.data,
          createdAt: new Date(),
          isActive: 1,
        });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_NEW_CUSTOMER_IS_LOADING", false);
        commit("SET_CUSTOMERS_ARE_LOADING", false);
        return true;
      } else if (response.message === "REGISTERED") {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message:
              "Eklenmek istenen kullanıcı daha önce sisteme kayıt edilmiştir.",
          },
        });
        commit("SET_NEW_CUSTOMER_IS_LOADING", false);
        commit("SET_CUSTOMERS_ARE_LOADING", false);
        return false;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        commit("SET_NEW_CUSTOMER_IS_LOADING", false);
        commit("SET_CUSTOMERS_ARE_LOADING", false);
        return false;
      }
    },
    // yeni eğitmen kayıt etme
    async SEND_NEW_INSTRUCTOR({ commit }, { fd, form }) {
      commit("SET_NEW_CUSTOMER_INSTRUCTOR_IS_LOADING", true);
      commit("SET_CUSTOMERS_INSTRUCTOR_ARE_LOADING", true);
      const response = await vm.$api.post(`Users/RegisterInstructor`, fd);
      if (response.message === "OK" && response.result === "OK") {
        commit("SET_NEW_CUSTOMER_INSTRUCTOR_TO_LIST", {
          ...form,
          id: response.data,
          createdAt: new Date(),
          isActive: 1,
        });
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "success",
            message: i18n.t("insertIsSucceed"),
          },
        });
        commit("SET_NEW_CUSTOMER_INSTRUCTOR_IS_LOADING", false);
        commit("SET_CUSTOMERS_INSTRUCTOR_ARE_LOADING", false);
        return true;
      } else if (response.message === "REGISTERED") {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message:
              "Eklenmek istenen kullanıcı daha önce sisteme kayıt edilmiştir.",
          },
        });
        commit("SET_NEW_CUSTOMER_IS_LOADING", false);
        commit("SET_CUSTOMERS_INSTRUCTOR_ARE_LOADING", false);
        return false;
      } else {
        commit("SET_LOADING_MODAL", {
          loading: true,
          alertBox: {
            status: true,
            variant: "danger",
            message: i18n.t("insertIsNotSucceed"),
          },
        });
        commit("SET_NEW_CUSTOMER_IS_LOADING", false);
        commit("SET_CUSTOMERS_ARE_LOADING", false);
        return false;
      }
    },
  },
};
