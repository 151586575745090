import Vue from "vue";
import Vuex from "vuex";
import User from "./modules/user";
import Category from "./modules/category";
import Courses from "./modules/courses";
import Products from "./modules/products";
import Books from "./modules/books";
import Blogs from "./modules/blogs";
import Events from "./modules/events";
import Customers from "./modules/customers";
import CustomerList from "./modules/customerDetail";
import Comments from "./modules/comments";
import Seances from "./modules/seances";
import Rezervation from "./modules/rezzervation";
import Settings from "./modules/settings";
import PaymentMethods from "./modules/paymentMethods";
import Coupons from "./modules/coupons";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // heocademy.com dan paket satın alma işlemi ile gelen kullanıcı.
    packageIdFromWebsite: "",
    loadingModal: false,
    loadingModalAlertBox: { status: false },
    progressEvent: {
      status: true,
      uploading: false,
      progress: 0,
      total: 0,
      loaded: 0,
    },
    mailDrawer: {
      status: false,
      form: {
        to: "",
        bcc: "",
        subject: "",
        content: "",
        files: [],
      },
    },
    currencyPrices: {
      success: true,
      date: "2024-05-31T10:28",
      rates: {
        TRY: 35.01,
        USD: 1.09,
        EUR: 1,
      },
    },
  },
  getters: {
    getCurrencyPrices: (state) => state.currencyPrices,
    getPackageIdFromWebsite: (state) => state.packageIdFromWebsite,
  },
  mutations: {
    SET_LOADING_MODAL(state, payload) {
      state.loadingModal = payload.loading;
      state.loadingModalAlertBox = payload.alertBox;
    },
    SET_MAIL_DRAWER(state, payload) {
      state.mailDrawer = payload;
    },
    SET_PROGRESS_BAR(state, payload) {
      state.progressEvent = payload;
    },
    SET_CURRENCY_PRICES(state, payload) {
      state.currencyPrices = payload;
    },
    SET_PACKAGE_ID_FROM_WEBSITE(state, payload){
      state.packageIdFromWebsite = payload;
    },
  },
  actions: {
    GET_CURRENCY_PRICES() {
      return;
      // var requestOptions = {
      //   method: 'GET',
      //   redirect: 'follow'
      // };

      // fetch("https://api.exchangeratesapi.io/v1/latest?access_key=b49d08287057faced85dd91d9618c3a5&base=EUR&symbols=TRY,USD,EUR", requestOptions)
      //   .then(response => response.json())
      //   .then(result => commit('SET_CURRENCY_PRICES', result))
      //   .catch(error => console.log('error', error))
    },
  },
  modules: {
    User,
    Category,
    Courses,
    Books,
    Products,
    Events,
    Seances,
    Blogs,
    Customers,
    CustomerList,
    Comments,
    Rezervation,
    Settings,
    PaymentMethods,
    Coupons,
  },
});
